import React, { Fragment } from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../../components/common/Layout/layout"
import SEO from "../../components/common/SEO/seo"
import BannerPage from "../../components/banner/BannerPage/bannerPage"
import SectionTextImage from "../../components/section/TextImage/textImage"
import CallToAction from "../../components/section/CallToAction/callToAction"

const ImpresionOffsetPage = () => {
    const data = useStaticQuery(graphql`
    query {
      impresionOffsetImage1: file(relativePath: { eq: "images/sections/produccion-grafica/impresion-offset/impresion-grandes-tirajes-ibxagency.png" }) {
        childImageSharp {
          fluid(maxWidth: 854) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      impresionOffsetImage2: file(relativePath: { eq: "images/sections/produccion-grafica/impresion-offset/colores-personalizados-ibxagency.png" }) {
        childImageSharp {
          fluid(maxWidth: 854) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      impresionOffsetImage3: file(relativePath: { eq: "images/sections/produccion-grafica/impresion-offset/que-materiales-imprimimos-ibxagency.png" }) {
        childImageSharp {
          fluid(maxWidth: 854) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

    return (
        <Layout>
            <SEO
                title="Imprenta Offset"
                description="Imprenta en Barcelona experta en la impresión offset. Impresión de catálogos, flyers, folletos de última tecnología."
            />

            <BannerPage
                title="Expertos en impresión offset"
                text="Imprimimos tus catálogos, flyers y folletos"
                color="blue"
            />

            <SectionTextImage
                title1="Impresión "
                title2="de grandes tirajes"
                text={<Fragment>
                    La <strong>impresión offset</strong> es aquella indicada para la <strong>producción de grandes tirajes</strong>: <br /><br />
                    <ol className="text-left">
                        <li>Impresión editorial o de catálogos corporativos</li>
                        <li>Impresión con soportes especiales</li>
                        <li>Impresión de acabados especiales</li>
                    </ol>
                </Fragment>}
                image={data.impresionOffsetImage1.childImageSharp.fluid}
                imageAlt="Impresión de grandes tirajes - Agencia Impresión Offset"
                color="blue"
                containerTextRight={false}
            />

            <SectionTextImage
                title1="Colores "
                title2="personalizados"
                text={<Fragment>
                    En IBX siempre buscamos la excelencia. Es por eso, que cuando se trata de <strong>imprimir un color corporativo personalizado</strong> o <strong>pantone concreto</strong>, siempre aplicamos la tecnología offset, una técnica que garantiza la máxima calidad en la precisión de un color.
                </Fragment>}
                image={data.impresionOffsetImage2.childImageSharp.fluid}
                imageAlt="Colores personalizados - Agencia Impresión Offset"
                color="blue"
                containerTextRight={true}
            />

            <SectionTextImage
                title1="¿Qué materiales "
                title2="imprimimos?"
                text={<Fragment>
                    En IBX producimos como bien hemos dicho un gran abanico de productos: <br /><br />
                    <ul class="text-left">
                        <li>Papelería personalizada con pantones</li>
                        <li>Flyers, trípticos, dípticos</li>
                        <li>Revistas corporativos</li>
                        <li>Catálogos de productos, servicios o tarifas</li>
                    </ul>
                </Fragment>}
                image={data.impresionOffsetImage3.childImageSharp.fluid}
                imageAlt="Tipos de marteriales impresión - Agencia Impresión Offset"
                color="blue"
                containerTextRight={false}
            />

            <CallToAction
                color="blue"
                title="¿Necesita tu empresa una imprenta offset?"
                text={<Fragment>
                    ¡Somos expertos en <strong>impresión offset en Barcelona</strong>!
                </Fragment>}
                button="¡Llámanos y pide presupuesto!"
                buttonUrl="/contacto"
            />

        </Layout>
    )

}
export default ImpresionOffsetPage
